var ja = {
    "languageArr": ["中", "EN", "JPN"],
    "login": "登録",
    "search": {
        "title": "検索",
        "cateArr": ["全部です", "アプリマーケット", "製品紹介", "新着情報"],
        "placeholder": "キーワード検索をお願いします。",
        "record": "歴史的記録です"
    },
    "product_search": {
        "placeholder": "製品名、製品種別、用途などを入力お願いします。",
        "btn": "検索"
    },
    "statistics_arr": ["スキル", "特許件数", "開発センターの面積", "開発担当者", "科学研究機関"],
    "unit_arr": ["項です", "項です", "㎡", "+", "です"],
    "market_language": {
        "title": "アプリマーケット",
        "desc": "ハイエンド無機・非金属粉体材料のアプリケーションとソリューションに注力",
        "market": ["電子情報材料", "新エネルギー材料", "半導体材料", "その他の業界"],
        "btn": "もっと詳しく知ります"
    },
    "product_language": {
        "title": "製品紹介",
        "desc": "お客様を中心として、イノベーションを継続",
        "product": [
            { "name": "シリカ系", "desc": "シリカ微粉、球形シリカ、球形シリカスラリー" }, 
            { "name": "アルミ系", "desc": "角形酸化アルミニウム、球状酸化アルミニウム" }, 
            { "name": "窒化系", "desc": "窒化アルミニウム、窒化ホウ素" },
            { "name": "チタン系", "desc": "単結晶酸化チタン" },
            { "name": "セラミック系", "desc": "チタン酸バリウム、ペタライト" },
            { "name": "電子パステ", "desc": "焼結銅ペースト、硬化銅ペースト" }
        ]
    },
    "group_introduction": {
        "title": "会社紹介",
        "inroduction": {
            "para1": "2005年に設立された蘇州錦芸新材料株式会社は、ハイエンドの無機非金属粉末新材料応用ソリューションを提供することを目指し、研究開発、生産、販売、技術サービスを統合した国家ハイテク企業であり続けています。 顧客の懸念の圧力と課題に焦点を当て、同社は無機非金属材料の可能性を利用し、独自のコア技術とコアの利点を通じて、様々な有機材料を統合し、優れた機能性と低コストの粉体ソリューションを提供し、精力的に新エネルギー、新素材、環境保護、省エネなどの急速に発展している分野でのアプリケーションを拡大しています。",
            "para2": "蘇州に本社を置き、南京航空航天大学、西南科技大学など国内の有名な機関と提携し、各分野の専門技術人材を吸収し、無機非金属粉体材料業界の独特な研究開発プラットフォームを構築しています。",
            "para3": "本社は国内の様々な無機非金属材料の優秀な企業、有利な資源、優秀な埋蔵量と協力し、生態チェーンプラットフォームを構築し、大手多国籍企業、上場企業、国内の優秀な中小企業にサービスを提供し、絶えず費用対効果の高い製品を導入し、顧客価値を創造し、資本増価を実現します。"
        },
    },
    "core_advantage": {
        "title": "2.コアの強み",
        "desc": "顧客価値を創造し、資本を増やす",
        "core_arr": [
            { "name": "多様な専門技術", "desc": "複合調合、超微粉砕<br />精密分級、微細球状化<br />表面処理、特注スラリー<br />" },
            { "name": "様々な無機材料", "desc": "無機的なものをベースに、有機的なものと手を携えて<br />統合と革新、再生と反復<br />" },
            { "name": "リーン生産プラットフォーム", "desc": "融通の利く生産、迅速な納品<br />品質向上、環境保護<br />" },
            { "name": "厳密な品質管理", "desc": "完璧な品質システム<br />高度な検査機器<br />優れた生産設備<br />権威ある試験方法<br />" },
            { "name": "カスタマイズ・ソリューション", "desc": "顧客の要望、共同研究開発<br />共同テスト、業界の優位性<br />" },
            { "name": "オープンな協力プラットフォーム", "desc": "外資系企業、国有企業<br />研究機関、企業家チーム<br />" }
        ]
    },
    "our_growth": {
        "title": "私たちの成長です"
    },
    "company_culture": {
        "title": "企業文化",
        "culture": [
            { "title": "ビジョン", "desc": "ハイエンドの無機非金属材料のアプリケーションとソリューションのプロバイダー" },
            { "title": "ミッション", "desc": "顧客からのプレッシャーと課題に焦点を当て、無機非金属材料の可能性を引き出す" },
            { "title": "価値観", "desc": "顧客本位、革新主導、闘争志向、職人技の実践" }
        ]
    },
    "talent_concept": {
        "title": "人材展望",
        "concept": [
            { "title": "プロフェッショナリズム", "desc": "絶え間ない学習、知識と行動の一体化<br />才能を最大限に生かすことにこだわり、職人精神を実践する" },
            { "title": "エクセレンス", "desc": "進取の気性に富み、卓越を追求し<br />究極のパフォーマンスを追求し、並外れた価値を達成する" },
            { "title": "イノベーション", "desc": "オープンさと寛容さ、変化を受け入れること<br />継続的改善へのコミットメント、革新する勇気に満ちている" }
        ]
    },
    "school": {
        "title": "学校と企業の協力"
    },
    "development": {
        "title": "キャリア育成",
        "desc1": "当社は管理職と専門職の2つのキャリア開発経路を提供しています。 専門チャンネルには、マーケティング、技術、機能、運営の4つの系列が含まれています。",
        "desc2": "デュアルチャンネル・スタッフ・グレード開発システム"
    },
    "salary": {
        "title": "給与パッケージ",
        "salary_arr": [
            { "title": "基本給", "desc": "基本給、業績給、出勤手当、ほかの手当、補助金" },
            { "title": "社会保険", "desc": "会社は全従業員を対象に”5つの保険と1つの金”に入ることを義務づけている" },
            { "title": "ライフサービス", "desc": "宿泊施設、シャトルバス、カフェテリア、スポーツ・フィットネス施設ある" },
            { "title": "報奨金制度", "desc": "期末報奨金、プロジェクト突破報奨金、株式、オプションなど" },
            { "title": "スタッフの能力開発", "desc": "多様な研修プログラムとキャリア開発計画" }
        ],
        "btn": "採用情報"
    },
    "subsidiary": {
        "btn": "/詳細情報"
    },
    "honor": {
        "title": "名誉と資格"
    },
    "wechat_code": "公式マイクロシグナルです",
    "contact": {
        "contact_us": "連絡先",
        "phone": "連絡先",
        "mail": "メール",
        "address": "会社住所",
        "address_detail": "江蘇省常熟市経済技術開発区興港路25-1",
        "copyright": "Copyright©️ 2024蘇州錦芸新材料株式会社",
        "download": "ダウンロード",
        "summary": "サマリー",
        "feature": "特徴",
        "function": "機能"
    },
    "fill_information": {
        "title": "申請資料情報記入",
        "desc": "資料を記入後、関連製品ファイルを自動的にダウンロード",
        "not_null": "必須",
        "name": "名前",
        "unit": "単位名",
        "department": "セクション",
        "mail": "メール",
        "confirm": "確認",
        "phone": "連絡先",
        "query": "クエリー",
        "select": "選択してください",
        "content": "具体的な内容",
        "submit": "確認"
    },
    "read_more": {
        "btn": "詳細"
    }
}

export default ja