var en = {
    "languageArr": ["中", "EN", "JPN"],
    "login": "Log in",
    "search": {
        "title": "search",
        "cateArr": ["All", "Application market", "Product center", "News"],
        "placeholder": "Please enter keyword search",
        "record": "historical record"
    },
    "product_search": {
        "placeholder": "Please enter product name, product category, use, etc.",
        "btn": "search"
    },
    "statistics_arr": ["Technical expertise", "Patents", "R&D center area", "R&D personnel", "Scientific research institution"],
    "unit_arr": ["item", "item", "㎡", "+", "the"],
    "market_language": {
        "title": "Application market",
        "desc": "Focus on the application and solutions of high-end inorganic, non-metallic powder materials",
        "market": ["Electronic information materials", "New energy materials", "Semiconductor materials", "Other industries"],
        "btn": "Learn more"
    },
    "product_language": {
        "title": "Product center",
        "desc": "Customer-centric, adhere to innovation driven",
        "product": [
            { "name": "Silica", "desc": "Silicon micropowder, spherical silicon, spherical silicon slurry" }, 
            { "name": "Alumina", "desc": "Angular aluminum, spherical aluminum" }, 
            { "name": "Nitride", "desc": "Aluminum nitride, boron nitride" },
            { "name": "Titanium", "desc": "Single-crystal titanium dioxide" },
            { "name": "Ceramic", "desc": "Barium titanate, petalite" },
            { "name": "Conductive paste", "desc": "Sintered copper paste, curing copper paste" }
        ]
    },
    "group_introduction": {
        "title": "Company introduction",
        "inroduction": {
            "para1": "Founded in 2005, Suzhou Ginet New Material Technology Co., Ltd. is committed to providing high-end inorganic non-metallic powder with new material application solutions, and is a national high-tech enterprise integrating research and development, production, sales and technical service. Focusing on the pressures and challenges of customer concerns, Ginet taps into the potential of inorganic non-metallic materials and, through its unique core technologies and core advantages, integrates a variety of organic materials to provide excellent functionality and low-cost powder solutions, vigorously expanding applications in new energy, new materials, environmental protection, energy saving and other fast-growing fields.",
            "para2": "Headquartered in Suzhou, Ginet is located in an excellent geographic location, together with Nanjing University of Aeronautics and Astronautics, Southwest University of Science and Technology and other well-known institutions, to absorb professional and technical personnel in various disciplines, to create a unique R & D platform for the inorganic non-metallic powder materials industry.",
            "para3": "Ginet cooperates with various domestic excellent enterprises of inorganic non-metallic materials, advantageous resources and excellent reserves to build an eco-chain platform, serving major multinational enterprises, listed enterprises and excellent domestic small and medium-sized enterprises, and continuously launching cost-effective products, creating customer value and realizing capital appreciation."
        }
    },
    "core_advantage": {
        "title": "Core Strengths",
        "desc": "Creating customer value and realizing capital appreciation",
        "core_arr": [
            { "name": "Diverse technical expertise", "desc": "Compound formula, ultra-fine grinding<br />Precision grading, fine spheroidization<br />Surface treatment, customized slurry<br />" },
            { "name": "Various inorganic materials", "desc": "Based on inorganic, partnering with organic<br />Integration and innovation, renewal and iteration<br />" },
            { "name": "Lean Production Platform", "desc": "Flexible production, speedy delivery<br />Quality improvement, environmental protection<br />" },
            { "name": "Strict quality control", "desc": "Complete quality system<br />Advanced testing instruments<br />Excellent production equipment<br />Authoritative testing methods<br />" },
            { "name": "Customized Solutions", "desc": "Customer demand, joint research and development<br />Joint experiments, industry advantages<br />" },
            { "name": "Open Cooperation Platform", "desc": "Foreign-funded enterprises, state-owned enterprises<br />Research institutes, entrepreneurial teams<br />" }
        ]
    },
    "our_growth": {
        "title": "Our growth"
    },
    "company_culture": {
        "title": "Company culture",
        "culture": [
            { "title": "Vision", "desc": "High-end inorganic non-metallic material application and solution provider" },
            { "title": "Mission", "desc": "Focusing on the pressures and challenges of customer concerns, unlocking the potential of inorganic non-metallic materials" },
            { "title": "Values", "desc": "Customer-centered, innovation-driven, striver-oriented, and practicing the spirit of craftsmanship" }
        ]
    },
    "talent_concept": {
        "title": "Outlook on personnel",
        "concept": [
            { "title": "Professionalism", "desc": "Continuous learning, unity of knowledge and action<br />insisting on making the best use of talents and practicing the spirit of craftsmanship" },
            { "title": "Excellence", "desc": "forge ahead and strive for excellence<br />pursue the ultimate performance and achieve extraordinary value" },
            { "title": "Innovation", "desc": "openness and tolerance, embracing change<br />commitment to continuous improvement, and the courage to innovate" }
        ]
    },
    "school": {
        "title": "University-Enterprise Cooperation"
    },
    "development": {
        "title": "Career development",
        "desc1": "Ginet provides management and professional dual-channel career development path.",
        "desc2": "The professional channel includes 4 sequences of marketing, technology, function and operation."
    },
    "salary": {
        "title": "Remuneration and benefits",
        "salary_arr": [
            { "title": "Base salary", "desc": "Base salary, merits, full attendance bonus, allowances, subsidies" },
            { "title": "Social insurance", "desc": "the company equips “five insurance and one gold” for all employees" },
            { "title": "Life services", "desc": "accommodation, shuttle bus, cafeteria, sports and fitness venues available" },
            { "title": "Incentive mechanism", "desc": "year-end awards, project breakthrough awards, equity, option incentives, etc." },
            { "title": "Employee Development", "desc": "Diversified training programs and career development planning" }
        ],
        "btn": "Join us"
    },
    "subsidiary": {
        "btn": "Learn More"
    },
    "honor": {
        "title": "Honor and qualification"
    },
    "wechat_code": "Official wechat account",
    "contact": {
        "contact_us": "Contact Us",
        "phone": "Contact number",
        "mail": "Mail",
        "address": "Add",
        "address_detail": "25-1 Xinggang Road, Changshu Economic and Technological Development Zone, Jiangsu Province, China",
        "copyright": "Copyright©️ 2024 Suzhou Ginet New Material Technology Co., Ltd",
        "download": "Download",
        "summary": "summary",
        "feature": "feature",
        "function": "function"
    },
    "fill_information": {
        "title": "Fill in the application information",
        "desc": "After filling in the information, automatically download the relevant product files",
        "not_null": "Not null",
        "name": "Name",
        "unit": "Unit Name",
        "department": "Department",
        "mail": "E-mail",
        "confirm": "confirm",
        "phone": "Contact number",
        "query": "Query",
        "select": "select",
        "content": "Content",
        "submit": "Submit"
    },
    "read_more": {
        "btn": "Read More"
    }
}

export default en