import request from "../http/index";

export function getNavListFunction(){
 return request({
    url: '/nav/nav/list',
    method: 'get'
 })
}
 
export function getHomeInfoFunction(){
    return request({
        url: '/home/list',
        method: 'get'
    })
}