<template>
  <div class="icons">
    <el-popover width="200" placement="left" trigger="click">
      <div>
        <div class="item">
          <span class="el-icon-phone"></span>
          <span>电话: 400-718-7171</span>
        </div>
        <div class="item">
          <span class="el-icon-location"></span>
          <span>地址: 苏州锦艺新材料科技股份有限公司</span>
        </div>
      </div>
      <!-- <span class="iconBottom" slot="reference">客服</span> -->
      <img class="iconBottom" src="../assets/service.png" slot="reference" alt="锦艺" />
    </el-popover>
    <!-- <span class="iconBottom" @click="scrollTop">顶部</span> -->
    <img class="iconBottom" src="../assets/top.png" @click="scrollTop" alt="锦艺" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    openPop() {},
    scrollTop() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.icons {
  position: fixed;
  right: 10px;
  bottom: 10%;
  z-index: 10000;
  display: block;
}
.icons .iconBottom {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border-radius: 50%;
}
.item {
  margin-bottom: 10px;
}
.item span:nth-child(1) {
  margin-right: 5px;
}
.item:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .icons {
    display: none;
  }
}
</style>