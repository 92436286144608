import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import { 
    Button,
    Pagination,
    Empty,
    Form,
    FormItem,
    Input,
    Select,
    Option,
    Image,
    Popover
} from 'element-ui';

Vue.use(Button);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Image);
Vue.use(Popover);