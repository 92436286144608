import axios from "axios";

// const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4129/web/api/' : 'http://1.117.69.77:4129/web/api/';
const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4129/web/api/' : 'https://www.ginet.cn/web';
const request = axios.create({
    baseURL,
    timeout: 50000
});

let language = localStorage.language ?? 'zh';

request.interceptors.request.use(config => {
    config.headers.Language = language;
    return config;
});

request.interceptors.response.use(res => {
    return res.data
}, err => {
    return '服务器响应数据失败'
})

export default request;