<template>
  <div class="footer">
    <div class="footer_container">
      <div class="footer_main">
        <div class="footer_top">
          <div class="logo" @click="$router.push('/home/page')">
            <img src="../assets/bottomLogo.png" alt="锦艺" />
          </div>
        </div>
        <div class="footer_wrapper">
          <div class="footer_nav">
            <div
              class="nav_item"
              v-for="(item, index) in navList.slice(1)"
              :key="index"
            >
              <div class="nav_sub" @click="goNext(item)">
                <span>{{ item.meta.title }}</span>
              </div>
              <div
                class="nav_sub"
                v-for="(subItem, idx) in item.children"
                :key="idx"
              >
                <span @click="goNextTwo(subItem)">{{
                  subItem.meta?.title ?? subItem.name
                }}</span>
              </div>
            </div>
          </div>
          <div class="footer_code">
            <div class="nav_sub">
              <span>{{ $t('wechat_code') }}</span>
            </div>
            <img src="../assets/code.jpg" alt="锦艺" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer_record">
      <div class="record_top">
        <span>{{ $t("contact.phone") }}: 400-718-7171</span>
        <span>{{ $t("contact.mail") }}: sales@ginet.cn</span>
        <span>{{ $t("contact.address") }}: {{ $t("contact.address_detail") }}</span>
      </div>
      <div class="record_bottom">
        <span>{{ $t("contact.copyright") }}</span>
        <span @click="goRecord" style="cursor: pointer">备案号: 苏ICP备18038063号-1</span>
      </div>
      <div style="width: 300px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002002140"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img src="../assets/police.png" style="float: left; width: 20px" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            苏公网安备 32059002002140
          </p>
        </a>
      </div>
    </div>
    <div class="phone_footer_nav" v-show="footerNav.meta.title">
      <div class="phone_footer_nav_wrapper">
        <div class="phone_footer_item">
          <div class="phone_title" @click="footerNavShow = !footerNavShow">
            <span>{{ footerNav.meta.title }}</span>
            <span
              class="el-icon-arrow-right"
              :style="{ transform: footerNavShow ? 'rotate(90deg)' : '' }"
            ></span>
          </div>
          <div
            class="phone_footer_sub_container"
            :class="[footerNavShow ? 'active' : '']"
          >
            <div
              class="phone_footer_sub_item"
              v-for="(sub, idx) in footerNav.children"
              :key="idx"
              @click="phoneGoNextTwo(sub)"
            >
              <div class="phone_footer_sub_title" :style="{ color: subIndex === idx ? 'var(--first-color)' : '' }" @click="openSubChild(idx)">
                <span>{{ sub.meta?.title ?? sub.name }}</span>
                <span class="el-icon-arrow-right" :style="{ transform: subIndex === idx ? 'rotate(90deg)' : '' }"></span>
              </div>
              <div class="phone_footer_subChild_container" :class="[subIndex === idx ? 'subActive' : '']" v-if="sub.children.length">
                <div class="phone_footer_subChild_item" v-for="(subChild, k) in sub.children" :key="k">
                  <div class="phone_footer_subChild_title" :style="{ color: subIndex === idx && lastIndex === k ? 'var(--first-color)' : '' }" @click="openLastChild(k)">
                    <span>{{ subChild.name }}</span>
                    <span class="el-icon-arrow-right" :style="{ transform:  subIndex === idx && lastIndex === k ? 'rotate(90deg)' : '' }"></span>
                  </div>
                  <div class="phone_footer_lastChild_container" :class="[subIndex === idx && lastIndex === k ? 'lastActive' : '']" v-if="subChild.children.length">
                    <div class="phone_footer_lastChild_item" @click="phoneGoNextThree(last)" v-for="(last, l) in subChild.children" :key="l">
                      <span>{{ last.name }}</span>
                      <span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phone_footer">
      <div class="code">
        <img src="../assets/code.jpg" alt="锦艺" />
      </div>
      <div class="right_info">
        <div class="info_item">
          <span>{{ $t("contact.phone") }}: 400-718-7171</span>
          <span>{{ $t("contact.mail") }}: sales@ginet.cn</span>
        </div>
        <div class="info_item">
          <span>{{ $t("contact.address") }}: {{ $t("contact.address_detail") }}</span>
        </div>
        <div class="info_item">
          <span>{{ $t("contact.copyright") }}</span>
        </div>
        <div class="info_item">
          <span @click="goRecord" style="cursor: pointer"
            >备案号: 苏ICP备18038063号-1</span
          >
        </div>
        <div class="info_item">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002002140"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
          >
            <img src="../assets/police.png" style="float: left; width: 20px" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              苏公网安备 32059002002140
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      footerNav: {
        meta: {
          title: "",
        },
        children: [],
      },
      footerNavShow: false,
      aboutArr: [
        "homepage",
        "GroupIntroduction",
        "TalentConcept",
        "Subsidiary",
        "HonorQualification",
      ],
      subIndex: null,
      lastIndex: null
    };
  },
  methods: {
    goNext(item) {
      if (this.$route.path == item.link) return false;
      this.$router.push(item.link);
    },
    goNextTwo(item) {
      if (item.name === "marketlist") {
        if (this.$route.path === item.path) return false;
        this.$router.push(item.path);
      } else {
        if (this.$route.path === item.link) return false;
        this.$router.push(item.link);
      }
    },
    phoneGoNextTwo(item) {
      if (item.name === "marketlist") {
        if (this.$route.path === item.path) {
          return false;
        };
        this.$router.push(item.path);
      } else {
        if (this.$route.path === item.link || !item.path) {
          return false;
        };
        this.$router.push(item.link);
      }
    },
    phoneGoNextThree(item){
      let link = `/product/detail/${item._id}`;
      if (this.$route.path === link) return false;
      this.$router.push(link);
    },
    goRecord() {
      window.open("https://beian.miit.gov.cn/");
    },
    goPolice() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002002140"
      );
    },
    showPhoneBottomNav(name) {
        if (this.aboutArr.findIndex((item) => item === name) > -1) {
          this.footerNav = this.navList[4];
        } else if (name === "MarketDetail") {
          this.footerNav = this.navList[1];
        } else if (name === "ProductDetail") {
          this.footerNav = this.navList[2];
        } else if (name === "LatestNews") {
          this.footerNav = {
            meta: {
              title: "",
            },
            children: [
              {
                meta: { title: "" },
              },
            ],
          };
        }
    },
    openSubChild(index){
      if(this.subIndex === index) return this.subIndex = null;
      this.subIndex = index;
    },
    openLastChild(index) {
      if(this.lastIndex === index) return this.lastIndex = null;
      this.lastIndex = index;
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.footerNavShow = false;
        this.showPhoneBottomNav(to.name);
      },
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: var(--third-color);
  z-index: 9999;
  position: relative;
}
.footer_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 340px;
  cursor: pointer;
}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.footer_nav {
  width: 70%;
  display: flex;
}
.nav_item {
  margin-right: 20px;
  width: calc(25% - 20px);
}
.nav_sub {
  color: #7e7c7d;
  margin-bottom: 20px;
  cursor: pointer;
}
.nav_sub:first-child {
  color: #000;
  font-weight: bold;
}
.footer_code {
  width: 178px;
  height: 178px;
}
.footer_record {
  background-color: var(--fourth-color);
  text-align: center;
  padding: 20px 0;
  font-size: var(--first-10-size);
  color: #fff;
}
.record_top {
  margin-bottom: 10px;
}
.record_top span {
  margin-right: 20px;
}
.record_top span:last-child {
  margin-right: 0;
}
.phone_footer {
  display: none;
}
.phone_footer_nav {
  display: none;
}
@media screen and (max-width: 768px) {
  .footer {
    background-color: transparent;
  }
  .footer_container {
    display: none;
  }
  .footer_record {
    display: none;
  }
  .phone_footer_nav {
    display: block;
  }
  .phone_footer {
    display: flex;
    align-items: flex-end;
    padding: 20px;
    background-color: var(--third-color);
  }
  .phone_footer .code {
    margin-right: 10px;
  }
  .right_info {
    font-size: 10px;
  }
  .info_item {
    margin-bottom: 2px;
  }
  .phone_title {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    background-color: var(--first-color);
    color: #fff;
  }
  span.el-icon-arrow-right {
    transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .phone_footer_sub_container {
    background-color: #d9d9d9;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .phone_footer_sub_container.active {
    height: auto;
    opacity: 1;
    visibility: visible;
    transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .phone_footer_sub_item {
    border-bottom: 1px solid #d3d3d3;
  }
  .phone_footer_sub_item:last-child {
    border-bottom: 0;
  }
  .phone_footer_sub_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .phone_footer_subChild_container {
    background-color: #C9C9C9;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .phone_footer_subChild_container.subActive {
    height: auto;
    opacity: 1;
    visibility: visible;
    transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .phone_footer_subChild_item {
    border-bottom: 1px solid #d3d3d3;
  }
  .phone_footer_subChild_item:last-child {
    border-bottom: 0;
  }
  .phone_footer_subChild_title {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
  .phone_footer_lastChild_container {
    background-color: #969696;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .phone_footer_lastChild_container.lastActive {
    height: auto;
    opacity: 1;
    visibility: visible;
    transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .phone_footer_lastChild_item {
    font-size: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
  }
  .phone_footer_lastChild_item:last-child {
    border-bottom: none;
  }
}
</style>