<template>
  <div>
    <header-nav ref="headerNav" :navList="navList"></header-nav>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <other-icon></other-icon>
    <footer-nav ref="footerNav" :navList="navList"></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "../components/HeaderNav.vue";
import FooterNav from "../components/FooterNav.vue";
import OtherIcon from "../components/OtherIcon.vue";
import { getNavListFunction } from "../api/home";
export default {
  components: {
    HeaderNav,
    FooterNav,
    OtherIcon
  },
  data() {
    return {
      navList: [],
    };
  },
  methods: {
    async fetchNav() {
      const res = await getNavListFunction();
      if (res.code !== 200) return false;
      this.navList = res.data;
      localStorage.setItem('productList', JSON.stringify(this.navList[2].children))
    },
  },
  created() {
    this.fetchNav().then(() => {
      let windowWidth = document.documentElement.clientWidth;
      if (windowWidth < 768) {
        this.$refs.footerNav.showPhoneBottomNav(this.$route.name);
      }
    });
    window.addEventListener("resize", () => {
      let windowWidth = document.documentElement.clientWidth;
      if (windowWidth < 768) {
        this.$refs.footerNav.showPhoneBottomNav(this.$route.name);
      }
    });
  },
  watch: {
    $route() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.5s;
}

.fade-leave-active {
  transition: all 0.5s;
}
</style>