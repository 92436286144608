import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload';
import i18n from './i18n';
import Element from 'element-ui';
import './plugin/element';
import './assets/iconfont/iconfont.css';
import './common/root.css';

Vue.config.productionTip = false;

Vue.use(Element,
  {
    i18n: (key, value) => {
      i18n.t(key, value)
    }
  }
)

Vue.use(VueLazyload, {
  error: require('./assets/error.jpg'),
  loading: require('./assets/loading.gif'),
  attempt: 1,
  preLoad: 1.3
});


document.title = "苏州锦艺新材料科技股份有限公司"


new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
