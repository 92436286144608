import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import jaLocale from 'element-ui/lib/locale/lang/ja';
import langZh from '../languages/zh';
import langEn from '../languages/en';
import langJa from '../languages/ja';

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.language ?? 'zh',
    messages: {
        'zh': { ...langZh, ...zhLocale },
        'en': { ...langEn, ...enLocale },
        'ja': { ...langJa, ...jaLocale }
    },
    globalInjection: true
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
