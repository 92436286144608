<template>
  <div class="nav">
    <div class="logo" @click="goNext(navList[0], null)">
      <img src="../assets/logo.png" width="100" alt="锦艺" />
    </div>
    <div class="drawer_nav" v-show="drawerNav" @click="closeDrawerNav"></div>
    <div class="pc_container">
      <div class="nav_container">
        <div
          class="nav_item"
          v-for="(item, index) in navList.slice(1)"
          :key="index"
          ref="navItem"
        >
          <span
            :class="[clickNavIndex === index ? 'navActive' : '']"
            @click.prevent="goNext(item, index)"
            >{{ item.meta.title }}</span
          >
          <div
            class="other_nav_container"
            :class="[navIndex === index ? 'active' : '']"
          >
            <div class="other_nav">
              <div
                class="other_nav_item"
                v-for="(two, idx) in item.children"
                :key="idx"
              >
                <span @click.prevent="goNextTwo(two)">{{
                  two.meta?.title ?? two.name
                }}</span>
                <div class="sub_container" v-if="two.children.length">
                  <div
                    class="sub_item"
                    v-for="(three, k) in two.children"
                    :key="k"
                    :style="{
                      marginBottom: three.children.length ? '0' : '20px',
                    }"
                  >
                    <span @click.prevent="goNextThree(three.children)">{{
                      three.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_container">
        <div class="search_container">
          <div class="search_init" @click="openDrawer">
            <i class="icon iconfont icon-sousuo"></i>
            <span>{{ $t('search.title') }}</span>
          </div>
        </div>
        <div class="login_container">
          <div class="login">
            <i class="icon iconfont icon-yonghu"></i>
            <span>{{ $t('login') }}</span>
          </div>
        </div>
        <div
          class="language_container"
          ref="languageContainer"
          :class="{ languageActive: languageStatus }"
        >
          <div
            class="language"
            @click="changeLanguage(index)"
            v-for="(item, index) in $t('languageArr')"
            :key="index"
          >
            <span>{{
              languageStatus ? item : $t('languageArr')[languageIndex]
            }}</span>
          </div>
        </div>
      </div>
      <div class="drawer_bg" v-show="drawer" @click="closeDrawer"></div>
      <div class="drawer" v-show="drawer" ref="drawer">
        <div class="close" @click.prevent="closeDrawer">
          <span class="icon iconfont icon-cha"></span>
        </div>
        <div class="drawer_wrapper">
          <div class="drawer_container">
            <div class="category">
              <div
                class="category_item"
                v-for="(item, index) in $t('search.cateArr')"
                :key="index"
                :class="{ cateActive: index === cateIndex }"
                @click.prevent="changeCate(index)"
              >
                <span>{{ item }}</span>
                <span class="line"></span>
              </div>
            </div>
            <div class="search_wrapper">
              <span
                @click.prevent="search"
                class="icon iconfont icon-sousuo"
              ></span>
              <input
                v-model.trim="text"
                type="text"
                :placeholder="$t('search.placeholder')"
                clearable
                @keyup.enter="search"
              />
              <span
                @click.prevent="text = ''"
                class="icon iconfont icon-cha"
              ></span>
            </div>
            <div class="history_container">
              <div class="history_title">
                <span>{{ $t('search.record') }}</span>
                <span
                  @click="clearHistory"
                  class="icon iconfont icon-shanchu"
                ></span>
              </div>
              <div class="history_list">
                <div
                  class="history_item"
                  v-for="(item, index) in historyArr"
                  :key="index"
                  @click="text = item"
                >
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="phone_container">
      <div class="beger">
        <span class="icon iconfont icon-sousuo" @click="$router.push('/mobile/search')"></span>
        <div class="mobile_line" @click="openBeger">
          <span
            class="line_top"
            :style="{
              transform: small_status ? 'rotate(-45deg)' : '',
              top: small_status ? '0' : '',
            }"
          ></span>
          <span class="line_center" v-show="!small_status"></span>
          <span
            class="line_bottom"
            :style="{
              transform: small_status ? 'rotate(45deg)' : '',
              top: small_status ? '0' : '',
            }"
          ></span>
        </div>
      </div>
      <div
        class="nav_small_container"
        :style="{
          height: small_status ? '100vh' : '',
          top: small_status ? '60px' : '',
        }"
      >
        <div class="drawer_small_nav"></div>
        <div
          class="nav_small_wrapper"
          :style="{
            visibility: small_status ? 'visible' : 'hidden',
            opacity: small_status ? '1' : '0',
          }"
        >
          <div
            class="nav_small_item"
            v-for="(item, index) in navList.slice(1)"
            :key="index"
            @click="openSub(item)"
          >
            <div class="small_one_nav">
              <span>{{ item.meta.title }}</span>
              <span class="icon iconfont icon-arrow-right"></span>
            </div>
          </div>
          <div class="small_contact_info">
            <div class="contact_top">
              <div
                class="language_container"
                ref="languageContainer"
                :class="{ languageActive: languageStatus }"
              >
                <div
                  class="language"
                  @click="changeLanguage(index)"
                  v-for="(item, index) in $t('languageArr')"
                  :key="index"
                >
                  <span>{{
                    languageStatus ? item : $t('languageArr')[languageIndex]
                  }}</span>
                </div>
              </div>
              <div class="login_icon">
                <i class="icon iconfont icon-yonghu"></i>
              </div>
            </div>
            <div class="phone_number">
              <span>400-718-7171</span>
            </div>
          </div>
        </div>
        <div class="small_two_container" :style="{ transform: subShow ? 'translateX(0)' : 'translateX(100%)' }">
          <div class="sub_title">
            <span class="el-icon-arrow-left" @click="closeSub"></span>
            <span>{{ sub.name }}</span>
          </div>
          <div class="small_sub_container">
            <div class="small_sub_item" v-for="(item, index) in sub.children" :key="index">
              <div class="small_sub_name">
                <div class="small_sub_name_wrapper" @click="goSmallNextTwo(item)">
                  <span>{{ item.meta?.title ?? item.name }}</span>
                  <span class="el-icon-arrow-right" :style="{ transform: subIndex === index ? 'rotate(90deg)' : '' }" @click="openSubChild(index)"></span>
                </div>
                <div class="small_sub_wrapper" :class="[subIndex === index ? 'smallActive' : '']" v-if="item.children.length">
                  <div class="small_subChild_item" v-for="(subChild, idx) in item.children" :key="idx">
                    <div class="small_subChild_name">
                      <div class="small_subChild_name_wrapper">
                        <span>{{ subChild.name }}</span>
                        <span class="el-icon-arrow-right" :style="{ transform:  subIndex === index && lastIndex === idx ? 'rotate(90deg)' : '' }" @click="openLastChild(idx)"></span>
                      </div>
                      <div class="small_subLast_container" :class="[subIndex === index && lastIndex === idx ? 'lastActive' : '']" v-if="subChild.children.length">
                        <div class="small_subLast_item" @click="goSmallNextThree(last)" v-for="(last, k) in subChild.children" :key="k">
                          <span>{{ last.name }}</span>
                          <span class="el-icon-arrow-right"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../bus/bus";
export default {
  props: {
    navList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      idx: null,
      drawer: false,
      drawerNav: false,
      navIndex: null,
      clickNavIndex: null,
      cateIndex: 0,
      cateArr: ["全部", "应用市场", "产品中心", "新闻中心"],
      text: "",
      historyArr: localStorage.historyArr
        ? JSON.parse(localStorage.historyArr)
        : [],
      languageArr: ["zh", "en", "ja"],
      languageStatus: false,
      languageIndex: 0,
      small_status: false,
      sub: {},
      subShow: false,
      subIndex: null,
      lastIndex: null
    };
  },
  methods: {
    closeDrawerNav() {
      this.navIndex = null;
      this.drawerNav = false;
    },
    goNext(item, index) {
      this.drawerNav = index !== null ? true : false;
      this.navIndex = index;
      this.clickNavIndex = index;
      sessionStorage.setItem("clickNavIndex", index);
      if (this.$route.path == item.link) return false;
      this.$router.push(item.link);
    },
    goNextTwo(item) {
      this.closeDrawerNav();
      if (item.name === "marketlist") {
        if (this.$route.path === item.path) return false;
        this.$router.push(item.path);
      } else {
        if (this.$route.path === item.link) return false;
        this.$router.push(item.link);
      }
    },
    goNextThree(item) {
      this.closeDrawerNav();
      let link = `/product/detail/${item[0]._id}`;
      if (this.$route.path === link) return false;
      this.$router.push(link);
    },
    search() {
      if (this.text) {
        let index = this.historyArr.findIndex((item) => item === this.text);
        if (index < 0) {
          this.historyArr.unshift(this.text);
          localStorage.historyArr = JSON.stringify(this.historyArr);
        }
        if (
          this.$route.query.text !== this.text ||
          this.$route.query.index !== this.cateIndex
        ) {
          this.$router.push({
            path: "/search",
            query: {
              text: this.text,
              index: this.cateIndex,
            },
          });
        }

        this.closeDrawer();
      }
    },
    clearHistory() {
      this.historyArr = [];
      localStorage.historyArr = JSON.stringify(this.historyArr);
    },
    changeCate(index) {
      this.cateIndex = index;
    },
    openDrawer() {
      this.drawer = true;
      setTimeout(() => {
        this.$refs.drawer.style.transform = "translateY(0)";
      }, 100);
    },
    closeDrawer() {
      this.$refs.drawer.style.transform = "translateY(-100%)";
      this.text = "";
      setTimeout(() => {
        this.drawer = false;
      }, 400);
    },
    changeLanguage(index) {
      this.languageStatus = !this.languageStatus;
      if (!this.languageStatus) {
        this.languageIndex = index;
        localStorage.setItem("language", this.languageArr[index]);
        if(this.$route.params.id) {
          sessionStorage.setItem("clickNavIndex", null);
          this.$router.push('/home/page');
        }
        setTimeout(() => {
          this.$router.go(0);
        }, 300)
      }
    },
    openBeger(){
      this.small_status = !this.small_status;
      if(!this.small_status) {
        this.sub = {};
        this.subShow = false;
        this.subIndex = null;
        this.lastIndex = null;
      }
    },
    openSub(item) {
      this.sub.name = item.meta.title;
      this.sub.children = item.children;
      this.subShow = true;
    },
    closeSub(){
      this.sub = {};
      this.subShow = false;
    },
    openSubChild(index){
      if(this.subIndex === index) return this.subIndex = null;
      this.subIndex = index;
    },
    openLastChild(index) {
      if(this.lastIndex === index) return this.lastIndex = null;
      this.lastIndex = index;
    },
    goSmallNextTwo(item){
      if (item.name === "marketlist") {
        this.openBeger();
        if (this.$route.path === item.path) {
          return false;
        };
        this.$router.push(item.path);
      } else {
        if (this.$route.path === item.link || !item.path) {
          return false;
        };
        this.openBeger();
        this.$router.push(item.link);
      }
    },
    goSmallNextThree(item) {
      this.openBeger();
      let link = `/product/detail/${item._id}`;
      if (this.$route.path === link) return false;
      this.$router.push(link);
    }
  },
  created() {
    bus.$on("changePage", (data) => {
      let path;
      if (data.name === "应用市场") {
        path = this.navList[1].children[data.index].path;
      } else if (data.name === "产品中心") {
        path = this.navList[2].link;
      }
      this.$router.push(path);
    });
    bus.$on("emitSearch", () => {
      this.openDrawer();
    });
    this.clickNavIndex =
      sessionStorage.getItem("clickNavIndex") === "null"
        ? null
        : ~~sessionStorage.getItem("clickNavIndex");
    this.languageIndex = this.languageArr.findIndex(e => e === localStorage.language);
    if(this.languageIndex < 0) this.languageIndex = 0;
  },
  beforeDestroy() {
    bus.$off("changePage");
  },
};
</script>

<style src="./common/header.css" scoped>
</style>