import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from "../views/MainView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
    redirect: '/home/page',
    children: [
      {
        path: '/home/page',
        name: 'homepage',
        component: () => import('../views/HomePage/HomeView.vue')
      },
      {
        path: '/market/home/:id',
        name: 'MarketHome',
        component: () => import('../views/Market/MarketHome.vue'),
        props: true
      },
      {
        path: '/market/detail/:id',
        name: 'MarketDetail',
        component: () => import('../views/Market/MarketDetail.vue'),
        props: true
      },
      {
        path: '/product/home/:id',
        name: 'ProductHome',
        component: () => import('../views/Product/ProductHome.vue'),
        props: true
      },
      {
        path: '/product/detail/:id',
        name: 'ProductDetail',
        component: () => import('../views/Product/ProductDetail.vue'),
        props: true
      },
      {
        path: '/fill/information',
        name: 'FillInformation',
        component: () => import('../views/Product/FillInformation.vue')
      },
      {
        path: '/basic/data',
        name: 'BasicData',
        component: () => import('../views/InvestorRelations/BasicData.vue')
      },
      {
        path: '/stock/information',
        name: 'StockInformation',
        component: () => import('../views/InvestorRelations/StockInformation.vue')
      },
      {
        path: '/governance/system',
        name: 'GovernanceSystem',
        component: () => import('../views/InvestorRelations/GovernanceSystem.vue')
      },
      {
        path: '/interactive/communication',
        name: 'InteractiveCommunication',
        component: () => import('../views/InvestorRelations/InteractiveCommunication.vue')
      },  
      {
        path: '/investor/protection',
        name: 'InvestorProtection',
        component: () => import('../views/InvestorRelations/InvestorProtection.vue')
      },
      {
        path: '/information/disclosure',
        name: 'InformationDisclosure',
        component: () => import('../views/InvestorRelations/InformationDisclosure.vue')
      },
      {
        path: '/group/introduction',
        name: 'GroupIntroduction',
        component: () => import('../views/AboutUs/GroupIntroduction.vue')
      },
      {
        path: '/talent/concept',
        name: 'TalentConcept',
        component: () => import('../views/AboutUs/TalentConcept.vue')
      },
      {
        path: '/latest/news',
        name: 'LatestNews',
        component: () => import('../views/AboutUs/LatestNews.vue')
      },
      {
        path: '/news/detail/:id',
        name: 'NewsDetail',
        component: () => import('../views/AboutUs/NewsDetail.vue'),
        props: true
      },
      {
        path: '/subsidiary',
        name: 'Subsidiary',
        component: () => import('../views/AboutUs/Subsidiary.vue')
      },
      {
        path: '/subsidiary/detail/:id',
        name: 'SubsidiaryDetail',
        component: () => import('../views/AboutUs/SubsidiaryDetail.vue'),
        props: true
      },
      {
        path: '/honor/qualification',
        name: 'HonorQualification',
        component: () => import('../views/AboutUs/HonorQualification.vue')
      },
      {
        path: '/search',
        name: 'Search',
        component:() => import('../views/Search/Search.vue')
      }
    ]
  },
  {
    path: '/mobile/search',
    name: 'mobileSearch',
    component: () => import('../views/Search/MobileSearch.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
